html{
  overflow: hidden;
  width: 100%;
}


body {
    margin: 0;
    overflow-x: hidden;
    overflow-y: hidden;
    height: 100%;
    width: 100%;
    position: absolute;
    /* overflow-y: scroll;
    -webkit-overflow-scrolling: touch; */
    /* position: fixed; */
}

@font-face {
    font-family: 'franklingothic_demi';
    src: url('./Fonts/franklingothicurwcon-book-webfont.woff2') format('woff2'),
        url('./Fonts/franklingothicurwcon-book-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'franklingothic_book';
    src: url('./Fonts/franklingothicurwcon-demi-webfont.woff2') format('woff2'),
        url('./Fonts/franklingothicurwcon-demi-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

/* CUSTOM SCROLLBAR - FIREFOX DOESNT SUPPORT THIS */

/* width */
::-webkit-scrollbar {
    width: 4px;
  }
  
/* Track */
::-webkit-scrollbar-track {
  background: #fff;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #000;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #B4B4B4;
}

/* END OF CUSTOM SCROLLBAR */